import { EventType } from '@wix/editor-platform-sdk-types';

import { EditorScriptContext } from './types';
import { getWidgetId } from './utils';
import { BM_URL_INDEX_PAGE, Event, GfppAction } from './constants';
import { referFriendPage } from './pages';
import { openElementsPanel, openSwitchStatePanel, openLayoutPanel } from './panels';
import { ElementId as ReferFriendPageElementId } from '../components/referFriendPage/constants';
import { ElementId as ReferralPageElementId } from '../components/referralPage/constants';
import { changePreset as changeReferFriendPreset } from '../components/referFriendPage/editor';

const openManageProgramPage = async ({ editorSDK }: EditorScriptContext) => {
  await editorSDK.editor.openDashboardPanel('', {
    url: BM_URL_INDEX_PAGE,
    closeOtherPanels: true,
  });
};

export const handleEvents = async (context: EditorScriptContext) => {
  const { editorSDK } = context;

  await editorSDK.addEventListener(Event.DeleteApp as any, async () => {
    await editorSDK.application.uninstall('', {
      openConfirmation: true,
    });
  });

  await editorSDK.addEventListener(Event.Manage as any, async () => {
    await openManageProgramPage(context);
  });

  await editorSDK.addEventListener(EventType.widgetGfppClicked, async (event) => {
    const { id, componentRef } = event.detail;

    if (id === GfppAction.OpenElementsPanel) {
      await openElementsPanel(componentRef, context);
    } else if (id === GfppAction.ManageProgram) {
      await openManageProgramPage(context);
    } else if (id === GfppAction.OpenSwitchStatePanel) {
      await openSwitchStatePanel(componentRef, context);
    } else if (id === GfppAction.OpenLayoutPanel) {
      await openLayoutPanel(componentRef, context);
    }
  });

  await editorSDK.addEventListener(EventType.componentGfppClicked, async (event) => {
    const { id, role, controllerRef } = event.detail;

    if (id === GfppAction.OpenLayoutPanel) {
      const contentWidgetRole =
        role === ReferralPageElementId.ContentContainer
          ? ReferralPageElementId.Content
          : ReferFriendPageElementId.Content;

      // Content box layout GFPP buttons are not on content widgets but on parent content containers
      const [contentWidgetRef] = await editorSDK.document.components.findAllByRole('', {
        controllerRef,
        role: contentWidgetRole,
      });

      await openLayoutPanel(contentWidgetRef, context);
    }
  });

  await editorSDK.addEventListener(EventType.globalDesignPresetChanged, async (event) => {
    const { preset, componentRef } = event.detail;
    const widgetId = await getWidgetId(componentRef, context);

    if (widgetId === referFriendPage.widgetId && preset.startsWith('externalPreset-')) {
      const presetId = preset.slice(preset.indexOf('-') + 1);
      await changeReferFriendPreset(componentRef, presetId, context);
    }
  });
};
