import { ManagementActionsBuilder } from '@wix/yoshi-flow-editor';
import { EditorScriptContext } from '../types';
import { REFER_FRIEND_PAGE_ID } from '../../components/referFriendPage/constants';
import {
  BM_URL_INDEX_PAGE,
  BM_URL_MANAGE_PAGE_EMAILS_SECTION,
  BM_URL_MANAGE_PAGE,
  SETUP_REFERRAL_PROGRAM_HELP_ID,
} from '../constants';

export const configureMyBusinessActions = (
  { flowAPI, editorSDK }: EditorScriptContext,
  managementActionsBuilder: ManagementActionsBuilder,
) => {
  const {
    translations: { t },
    environment: { appDefinitionId },
  } = flowAPI;

  managementActionsBuilder.mainActions().addAction({
    title: t('my-business-panel.view-dashboard'),
    icon: 'pageSelector',
    onClick: async () => {
      await editorSDK.editor.openDashboardPanel('', {
        url: BM_URL_INDEX_PAGE,
        closeOtherPanels: false,
      });
    },
  });

  managementActionsBuilder.customActions().addAction(
    {
      title: t('my-business-panel.manage-program'),
      icon: 'appManager_settingsAction',
      type: 'dashboard',
      onClick: async () => {
        await editorSDK.editor.openDashboardPanel('', {
          url: BM_URL_MANAGE_PAGE,
          closeOtherPanels: false,
        });
      },
    },
    {
      title: t('my-business-panel.manage-referral-pages'),
      icon: 'appManager_pagesAction',
      type: 'editorActions',
      onClick: async () => {
        await editorSDK.editor.deeplink.show('', {
          type: 'pagesPanel',
          params: [appDefinitionId],
        });
      },
    },
    {
      title: t('my-business-panel.manage-emails'),
      icon: 'email_icon',
      type: 'dashboard',
      onClick: async () => {
        await editorSDK.editor.openDashboardPanel('', {
          url: BM_URL_MANAGE_PAGE_EMAILS_SECTION,
          closeOtherPanels: false,
        });
      },
    },
    {
      title: t('my-business-panel.customize-referrals-page'),
      icon: 'customizeDesignButtonBrush',
      type: 'editorActions',
      onClick: async () => {
        const pages = await editorSDK.document.pages.data.getAll('');
        const page = pages.find(({ tpaPageId }) => tpaPageId === REFER_FRIEND_PAGE_ID);
        const pageRef = { id: page?.id!, type: 'DESKTOP' } as const;

        await editorSDK.pages.navigateTo('', { pageRef });
      },
    },
  );

  managementActionsBuilder.learnMoreAction().set({
    id: SETUP_REFERRAL_PROGRAM_HELP_ID,
  });

  managementActionsBuilder.upgradeAction().set({
    upgradeType: 'SITE_UPGRADE',
    upgradeText: t('my-business-panel.upgrade-text'),
    upgradeLinkText: t('my-business-panel.upgrade-link-text'),
  });
};
